@import '../../scss/variables';
@import '../../scss/mixins';

.hero_full_video {
  background-image: none !important;
  overflow: hidden;
  color: $cookunity-white;
  height: 600px;
  position: relative;

  @include mobile {
    height: 500px;
  }

  &:after {
    content: ' ';
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(360deg, #131315 6.09%, transparent 99.99%);
    top: 0;

    @media (max-width: 1200px) {
      background: linear-gradient(0deg, #131315 2.09%, rgba(0, 0, 0, 0) 99.99%);
    }
  }

  .video_background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .container_cui {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    .content_left {
      position: absolute;
      z-index: 1;
      margin-top: 218px;

      @media (max-width: 1200px) {
        text-align: center;
        position: absolute;
        z-index: 1;
        margin: 290px auto 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
      }

      @media (max-width: 900px) {
        margin: 330px auto 0;
        height: 200px;
      }

      @include mobile {
        margin: 200px auto 0;
        height: 300px;
      }

      h1 {
        color: $cookunity-white;
        font-family: $cui-font-family-rossanova;
        font-size: $cui-font-7xl;
        font-size: 120px;
        line-height: 90%;
        margin-top: 0;
        width: 600px;
        margin-bottom: 60px;

        @media (max-width: 900px) {
          font-size: 56px;
          line-height: 48px;
          margin-bottom: 32px;
          width: 90%;
        }

        span {
          color: $cui-yellow-color-500;
        }
      }

      h4 {
        color: $cui-yellow-color-500;
        font-family: $cui-font-family-tt-norms;
        font-size: $cui-font-base;
        font-weight: $cui-font-bold;
        line-height: 24px;

        @media (max-width: 900px) {
          width: 90%;
        }
      }
    }
  }
}
