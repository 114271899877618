@import '../../scss/mixins';
@import '../../scss/variables';

figure.recipe_card {
  border-radius: 12px;
  box-shadow: 0px 0px 2px 0px rgba(35, 31, 32, 0.1),
    0px 2px 2px 0px rgba(0, 0, 0, 0.05);
  width: 404px !important;
  margin: 10px;
  height: 96%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1300px) {
    width: 304px !important;
  }

  @media (max-width: 980px) {
    width: 304px !important;
  }

  img {
    width: 100%;
    height: 253px;
    border-radius: 12px 12px 0px 0px;
    object-fit: cover;
  }

  figcaption {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    padding: 32px 28px 24px;
    justify-content: space-between;

    p {
      margin: 0;
      font-family: $cui-font-family-tt-norms;
      font-size: $cui-font-base;
      font-style: normal;
      font-weight: $cui-font-normal;
      line-height: $cui-line-height-2xl;
    }
    p.text {
      color: $cui-black-color-300;
      margin: 12px 0;
    }

    > div {
      display: flex;

      img {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        margin-right: 12px;
      }
    }

    p.autor {
      color: $cui-black-color-300;
    }
  }
}
figure.clickable {
  cursor: pointer;
}
