@import '../../../../../scss/variables';

.contentBlock {
  margin: 40px 0;
  font-family: $cui-font-family-tt-norms;

  a {
    color: $cui-wine-color-500;
  }

  th,
  td {
    border-width: 0.1em;
    border: solid 1px $cui-black-color;
    text-align: center;
  }

  th {
    background-color: $cui-black-color-300;
  }

  table {
    min-width: 50%;
    border: 1px solid $cui-black-color;
    border-collapse: collapse;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: $cui-font-family-rossanova;
  }
}
