@import '../../../scss/variables';

.hero_referral__proteins {
  max-width: 535px;
  background-color: $cookunity-black;
  padding: 40px;
  height: max-content;
  margin: auto 0 auto 100px;
  border-radius: $cui-border-radius-lg;

  &__title {
    color: $cookunity-white;
    font-family: $cui-font-family-rossanova;
    font-size: $cui-font-4xl;

    span {
      color: $cui-yellow-color-500;
    }
  }

  &__quiz_wrapper {
    margin: 28px 0 40px 0;
  }

  @media (max-width: 768px) {
    background: linear-gradient(
      180deg,
      rgba(35, 31, 32, 0) 0%,
      rgb(35, 31, 32) 20%,
      rgb(35, 31, 32) 100%
    );
    width: 100%;
    max-width: 100%;
    padding: 40px;
    margin: 0 auto;
    position: absolute;
    bottom: -200px;

    .hero_referral__proteins {
      &__title {
        font-size: 30px;
        text-align: center;
      }
    }
  }
}
