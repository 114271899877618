@import '../../scss/mixins';
@import '../../scss/variables';

.recipes {
  width: 100%;

  &__hero {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    text-align: center;

    h1 {
      color: $cui-black-color;
      text-align: center;
      font-family: $cui-font-family-rossanova;
      font-size: $cui-font-8xl;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: -1.92px;

      span {
        color: $cui-yellow-color-500;
      }
    }
  }

  &__container_tags {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__banner_blog {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    @include mobile {
      width: 100%;
    }
  }
}

.category_page {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 64px 0 108px;

  &__header {
    button {
      color: $cui-black-color;
      display: flex;
      align-items: center;
      border-radius: 200px;
      background: transparent;
      padding: 7px 20px 7px 16px;
      background: $cui-black-color-100;

      img {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
    }

    h3 {
      color: $cui-black-color;
      text-align: center;
      font-family: $cui-font-family-rossanova;
      font-size: $cui-font-5xl;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: -1.32px;
      margin: 0 0 68px;
    }
  }

  &__meals {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }
}
