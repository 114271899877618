@import '../../../scss/variables';

.radio_container {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
  position: relative;
  width: 100%;
}

.radio_input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom_radio {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  background-color: $cookunity-white;
  border-color: #dddddd;
  position: relative;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  display: block;

  &:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: $cui-wine-color-500;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.2s ease-in-out;
  }
}

.radio_input:checked + .custom_radio {
  border-color: $cui-wine-color-500;
}

.radio_input:checked + .custom_radio:after {
  transform: translate(-50%, -50%) scale(1);
}
