@import '../../scss/variables';

.logo_partnership {
  display: flex;
  align-items: center;
  gap: 8px;

  &__icon {
    display: flex;
    align-items: center;
  }

  &__text {
    font-size: $cui-font-sm;
    font-family: $cui-font-family-tt-norms;
  }

  &__cui_logo {
    padding: 8px;
    background: $cookunity-black;
    border-radius: $cui-border-radius-xxs;
    max-height: 32px;

    img {
      height: 17px;
    }
  }
}
